<template>
  <b-modal
      :ref="id"
      :id="id"
      :title="title"
      :ok-title="okTitle"
      title-class="text-uppercase"
      :modal-class="`modal-${variant}`"
      size="lg"
      centered
      hide-footer
  >
    <slot></slot>
    <hr/>
    <div class="d-flex justify-content-end" style="gap: 10px">
      <loading-button
          :variant="cancelVariant"
          :label="cancelTitle"
          @click="cancel"
      />
      <loading-button
          :label="okTitle"
          :variant="okVariant || variant"
          :is-loading="isLoadingButton"
          :is-disable="isLoadingButton"
          @click="ok"
      />
    </div>
  </b-modal>
</template>

<script>

import loadingButton from '@/views/components/LoadingButton.vue'

export default {
  name: 'CustomModal',
  components: {
    loadingButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default () {
        return 'Modal title'
      }
    },
    okTitle: {
      type: String,
      default () {
        return this.$t('cloudVps.msgBox.button.confirm')
      }
    },
    cancelTitle: {
      type: String,
      default () {
        return this.$t('cloudVps.msgBox.button.cancel')
      }
    },
    variant: {
      type: String,
      default: 'primary'
    },
    okVariant: {
      type: String,
      default: undefined
    },
    cancelVariant: {
      type: String,
      default: 'secondary'
    },
    isManualClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoadingButton: false
    }
  },
  methods: {
    ok () {
      this.$emit('ok')
      if (this.isManualClose) {
        this.isLoadingButton = true
      } else {
        this.hide()
      }
    },
    cancel () {
      this.$emit('cancel')
      this.hide()
    },
    disableLoading () {
      this.isLoadingButton = false
    },
    show () {
      this.$refs[this.id].show()
    },
    hide() {
      this.$refs[this.id].hide()
      this.isLoadingButton = false
    }
  }
}
</script>
